import { toHTML } from "@portabletext/to-html";
import { EditorialFullBleedProps } from "@bluebottlecoffee/design-system/components";
import { EditorialFullBleed as Schema } from "../sanity-schema";
import { toImageResponsiveProps } from "./image";
import { toLinkProps } from "./link";

export function toEditorialFullBleedProps(
  data: Schema,
  region: string,
  lang: string,
): EditorialFullBleedProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    ...(data.centered && { centered: data.centered }),
    // Don't check truthiness before passing. Component darkTheme is default true,
    // so if it is set to false in Sanity, data.darkTheme && {darkTheme: data.darkTheme }
    // will result in the false value never getting passed, and default prevails.
    ...{ darkTheme: data.darkTheme },
    ...(data.heading && { heading: toHTML(data.heading[lang]) }),
    ...(data.headingFontFamily && {
      headingFontFamily: data.headingFontFamily,
    }),
    ...(data.cta &&
      data.cta.text && { cta: toLinkProps(data.cta, region, lang) }),
    ...(data.subheading && { subheading: toHTML(data.subheading[lang]) }),
    image: toImageResponsiveProps(data.image, lang),
  };
}
