import { EditorialIconProps } from "@bluebottlecoffee/design-system/components";
import { toHTML } from "@portabletext/to-html";
import { EditorialIcon as Schema } from "../sanity-schema";
import { toImageProps } from "./image";

type EditorialIconTransformerProps = {
  data: Schema;
  lang: string;
};

export function toEditorialIconProps({
  data,
  lang,
}: EditorialIconTransformerProps): EditorialIconProps {
  return {
    name: toHTML(data.name[lang]),
    icon: toImageProps(data.icon, lang),
    ...(data.description && { description: toHTML(data.description[lang]) }),
  };
}
