import { toHTML } from "@portabletext/to-html";
import { EmailSignUpProps } from "@bluebottlecoffee/design-system/components";
import { Opacity } from "@bluebottlecoffee/design-system/components/lib/types";
import { EmailSignUp as EmailSignUpSchema } from "../sanity-schema";
import { toImageResponsiveProps } from "./image";

function isOpacityValue(imageOpacity?: number): imageOpacity is Opacity {
  const opacityValues = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  return opacityValues.includes(imageOpacity);
}

type EmailSignUpTransformerProps = {
  data: EmailSignUpSchema;
  lang: string;
};

export function toEmailSignUpProps({
  data,
  lang,
}: EmailSignUpTransformerProps): Omit<EmailSignUpProps, "onSubmit"> {
  const { confirmationCopy, signUpCopy } = data;
  return {
    confirmationCopy: {
      confirmButton: confirmationCopy.confirmationButton[lang],
      ...(confirmationCopy.description && {
        description: toHTML(confirmationCopy.description[lang]),
      }),
      ...(confirmationCopy.heading && {
        heading: toHTML(confirmationCopy.heading[lang]),
      }),
    },
    image: toImageResponsiveProps(data.image, lang),
    ...(isOpacityValue(data.imageOpacity) && {
      imageOpacity: data.imageOpacity,
    }),
    iterableListId: data.iterableListId ?? 0,
    signUpCopy: {
      ...(signUpCopy.description && {
        description: toHTML(signUpCopy.description[lang]),
      }),
      ...(signUpCopy.disclaimer && {
        disclaimer: toHTML(signUpCopy.disclaimer[lang]),
      }),
      ...(signUpCopy.heading && { heading: toHTML(signUpCopy.heading[lang]) }),
      inputPlaceholder: signUpCopy.inputPlaceholder[lang],
      submitButton: signUpCopy.submitButton[lang],
    },
  };
}
