import { OneClickSubscribeCardProps } from "@bluebottlecoffee/design-system/components";
import { toHTML } from "@portabletext/to-html";
import {
  LocalizedBlock,
  LocalizedString,
  Product,
  SubscriptionInterval,
} from "../sanity-schema";
import { toImageProps } from "./image";
import { DereferencedVariant } from "../sanity/variant-queries";

export type TrialSubscriptionSchema = {
  productVariant: DereferencedVariant;
  product: Product;
  interval: SubscriptionInterval;
};

export type OneClickSubscribeTransformerProps = {
  lang: string;
  copy: {
    assortmentButtonTextBeforeCost: LocalizedString;
  };
  isLoggedIn: boolean;
  loginCTAText?: string;
  trialSubscription: TrialSubscriptionSchema;
  onClick: () => Promise<void>;
};

function toText(
  localizedText: LocalizedString | LocalizedBlock | undefined,
  lang: string | undefined,
) {
  if (!localizedText) return null;

  const { _type } = localizedText;
  const text = localizedText[lang];

  return _type === "localizedBlock" ? toHTML(text) : text;
}

export function toOneClickSubscribeCard({
  copy: { assortmentButtonTextBeforeCost },
  isLoggedIn,
  loginCTAText,
  lang,
  trialSubscription,
  onClick,
}: OneClickSubscribeTransformerProps): OneClickSubscribeCardProps {
  const { product } = trialSubscription;
  const {
    name,
    description,
    quickSubscribeCard: {
      product: { bottomCopy, bottomLabel, image },
    },
  } = product;
  return {
    assortment: {
      description: toText(description, lang),
      image: toImageProps(image, lang),
      name: toText(name, lang),
    },
    bottomCopy: toText(bottomCopy, lang),
    bottomLabel: toText(bottomLabel, lang),
    buttonText: toText(assortmentButtonTextBeforeCost, lang),
    isLoginContext: !isLoggedIn,
    loginCTAText,
    name: toText(product.name, lang),
    onClick,
  };
}
