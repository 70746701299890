import {
  EmailSignUp,
  EmailSignUpProps,
} from "@bluebottlecoffee/design-system/components";
import { FunctionComponent } from "react";
import { Result } from "@bluebottlecoffee/design-system/components/lib/types";
import { subscribeToList } from "./integrations/iterable/subscribe-to-list";

type EmailSignUpWrapperProps = {
  props: Omit<EmailSignUpProps, "onSubmit">;
  genericError: string;
  invalidEmailError: string;
};

/* Handles subscribing the user to the Iterable list */
export const EmailSignUpWrapper: FunctionComponent<EmailSignUpWrapperProps> = ({
  props,
  genericError,
  invalidEmailError,
}) => {
  // Submits the user's email address to the Iterable API
  async function handleSubmit(email: string): Promise<Result> {
    return subscribeToList({
      email,
      genericError,
      invalidEmailError,
      listId: props.iterableListId,
    });
  }

  return <EmailSignUp {...props} onSubmit={handleSubmit} />;
};
