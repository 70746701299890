import { CafeProductsCarouselProps } from "@bluebottlecoffee/design-system/components";
import { CafeProductsCarousel } from "../sanity-schema";
import { toImageProps } from "./image";

export const toCafeProductsProps = (
  data: CafeProductsCarousel,
  lang: string,
): CafeProductsCarouselProps => ({
  cafeProductCards: data.cafeProductCards.map((productCard) => ({
    heading: productCard.title[lang],
    image: toImageProps(productCard.image, lang),
    url: productCard.url,
  })),
  title: data.title[lang],
});
