import { toHTML } from "@portabletext/to-html";
import { EditorialIconBlocksProps } from "@bluebottlecoffee/design-system/components";
import { EditorialIconBlock as Schema } from "../sanity-schema";
import { toEditorialIconProps } from "./editorial-icon";

type EditorialIconBlockTransformerProps = {
  data: Schema;
  lang: string;
};

export function toEditorialIconBlocksProps({
  data,
  lang,
}: EditorialIconBlockTransformerProps): EditorialIconBlocksProps {
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    ...(data.headline && { headline: toHTML(data.headline[lang]) }),
    ...(data.caption && { caption: toHTML(data.caption[lang]) }),
    editorialIcons: data.editorialIcons?.map((i) =>
      toEditorialIconProps({ data: i, lang }),
    ),
  };
}
