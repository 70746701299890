import { TwoUpValuePropsProps } from "@bluebottlecoffee/design-system/dist/modules/TwoUp/components";
import { toHTML } from "@portabletext/to-html";
import { ValueProps } from "../sanity-schema";
import { toLinkProps } from "./link";
import { toImageProps } from "./image";

export function toValueProps(
  data: ValueProps,
  lang: string,
  region: string,
): TwoUpValuePropsProps {
  const { text, url } = toLinkProps(data.cta, region, lang);

  return {
    heading: data.heading[lang],
    description: toHTML(data.description[lang]),
    button: {
      link: url,
      text,
    },
    editorialIcons: data.valueProps.map((value) => ({
      name: value.name[lang],
      icon: toImageProps(value.image, lang),
    })),
  };
}
