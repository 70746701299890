import { MenuItemSelectorProps } from "@bluebottlecoffee/design-system/components";
import { toHTML } from "@portabletext/to-html";
import { Cafe, CafeGroup, CafeGroupFeature } from "../sanity-schema";
import { Dialect } from "../utils/locale";
import { cafePage } from "../link-builders";

type DereferencedCafeGroup = Omit<CafeGroup, "cafes"> & {
  cafes: Cafe[];
};

export type DereferencedCafeGroupFeature = Omit<
  CafeGroupFeature,
  "cafeGroups"
> & {
  cafeGroups: DereferencedCafeGroup[];
};

export function cafeGroupFeatureToMenuItemSelectorProps({
  data,
  lang,
  region,
}: {
  data: DereferencedCafeGroupFeature;
  lang: Dialect["lang"];
  region: Dialect["region"];
}): MenuItemSelectorProps {
  const { bgColor, cafeGroups, heading, image, selectedItemHeading } = data;
  const imageResponsive = {
    desktop: {
      altText: image.desktop.altText[lang],
      src: image.desktop.source.public_id,
    },
    mobile: {
      altText: image.mobile?.altText?.[lang],
      src: image.mobile?.source?.public_id,
    },
  };
  const items = cafeGroups.map((cafeGroup) => ({
    key: cafeGroup.key[lang],
    value: cafeGroup.cafes.map((cafe) => {
      const { district, extended, locality, street, postalCode } = cafe.address;
      const addressString = `${street} ${
        extended || ""
      }<br/>${locality}, ${district} ${postalCode}`;
      return {
        key: cafe.name[lang],
        keyLink: {
          url: cafePage({ lang, region, slug: cafe.slug.current }),
          external: false,
        },
        value: addressString,
        valueLink: {
          url: cafe.cafeAddressLink,
          external: true,
        },
      };
    }),
  }));

  return {
    bgColor,
    heading: toHTML(heading[lang]),
    image: imageResponsive,
    items,
    selectedItemHeading: selectedItemHeading?.[lang],
  };
}
