import { FunctionComponent, PropsWithChildren, useEffect } from "react";

export type ComponentIdInjectorProps = PropsWithChildren<{
  id?: string;
}>;

/**
 * Replaces the id of the first child of the component with the id passed in.
 *
 * Use this component when you want to keep the hierarchical integrity of the
 * Component you're wrapping but want to change the id of the first child.
 *
 * The most common patterns in React is to either create a component that wraps
 * the children and attach the id to it, or to pass through the attributes to
 * the component and let the component choose where that attribute should go.
 *
 * The wrapper approach is fine, but it adds an extra div to the DOM which can
 * be problematic in cases where you want to keep the element from the
 * component (like when the component has a top-level `section`).
 *
 * The pass-through approach is also okay, but it requires the component to
 * know about the id attribute and how to pass it through. This component
 * allows you to keep the hierarchical integrity of the component you're
 * wrapping while still changing the id of the first child.
 *
 * Note: passing in an id of undefined will result in no changes to the child
 * components.
 */
export const ComponentIdInjector: FunctionComponent<
  ComponentIdInjectorProps
> = ({ id, children: reactChildren }) => {
  const dataAttributeId = "data-id-injector";

  useEffect(() => {
    // If no id is provided, do nothing
    if (!id) return;

    // Get the children of the sacrificial div from below
    const children = document.querySelector(
      `[${dataAttributeId}="${id}"]`,
    )?.children;

    // Replace the id of the first child with the id passed in
    if (children && children.length > 0) {
      const firstChild = children[0];
      firstChild.setAttribute("id", id);
    }

    // Replace the sacrificial div with its children
    const parentDiv = document.querySelector(`[${dataAttributeId}="${id}"]`);
    if (parentDiv) {
      const grandParentDiv = parentDiv.parentNode;

      if (parentDiv.firstChild) {
        grandParentDiv.insertBefore(parentDiv.firstChild, parentDiv);
      }

      grandParentDiv.removeChild(parentDiv);
    }
  }, []);

  return id ? (
    <div {...{ [dataAttributeId]: id }}>{reactChildren}</div>
  ) : (
    <>{reactChildren}</>
  );
};

ComponentIdInjector.defaultProps = {
  id: undefined,
};
