import { PromotionalBannerProps } from "@bluebottlecoffee/design-system/components";
import { PromotionalBanner as Schema } from "../sanity-schema";

export function toPromotionalBannerProps(
  data: Schema,
  lang: string,
): PromotionalBannerProps {
  return {
    bannerText: data.bannerText[lang],
    bannerColor: data.bannerColor,
    ctaText: data.ctaText[lang],
    ctaUrl: data.ctaUrl,
  };
}
