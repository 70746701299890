import { toHTML } from "@portabletext/to-html";
import { OneClickSubscribeModuleProps } from "@bluebottlecoffee/design-system/components";
import { OrderBig } from "@chordcommerce/react-autonomy";
import {
  LocalizedBlock,
  LocalizedString,
  SubscriptionInterval,
} from "../sanity-schema";
import {
  TrialSubscriptionSchema,
  toOneClickSubscribeCard,
} from "./one-click-subscribe-card";

function toText(
  localizedText: LocalizedString | LocalizedBlock | undefined,
  lang: string | undefined,
) {
  if (!localizedText) return null;

  const { _type } = localizedText;
  const text = localizedText[lang];

  return _type === "localizedBlock" ? toHTML(text) : text;
}

export type DereferencedOneClickSubscribeModuleProps = {
  heading: LocalizedBlock;
  options: TrialSubscriptionSchema[];
  cta: LocalizedString;
  loginCTAText?: LocalizedString;
};

export function toOneClickSubscribeModuleProps(
  data: DereferencedOneClickSubscribeModuleProps,
  lang: string,
  isLoggedIn: boolean,
  subscribeProduct: (options: {
    quantity: number;
    sku: string;
    interval: Pick<SubscriptionInterval, "length" | "unit">;
  }) => Promise<OrderBig>,
): OneClickSubscribeModuleProps {
  const cards = data.options.map((option) => {
    const onClick = async () => {
      const { sku } = option.productVariant;
      const { interval } = option;
      await subscribeProduct({
        sku,
        quantity: 1,
        interval,
      });
    };

    return toOneClickSubscribeCard({
      copy: { assortmentButtonTextBeforeCost: data.cta },
      isLoggedIn,
      loginCTAText: toText(data.loginCTAText, lang),
      trialSubscription: option,
      lang,
      onClick,
    });
  });

  return { cards, heading: toText(data.heading, lang) };
}
