import { toHTML } from "@portabletext/to-html";
import { CategoryCardProps } from "@bluebottlecoffee/design-system/components";
import { CategoryCard as Schema } from "../sanity-schema";
import { toImageProps } from "./image";
import { toLinkProps } from "./link";

export function toCategoryCardProps(
  data: Schema,
  region: string,
  lang: string,
  bgColor?: CategoryCardProps["bgColor"],
): CategoryCardProps {
  return {
    ...(bgColor && { bgColor }),
    heading: data.heading[lang],
    layout: data?.layout,
    link: toLinkProps(data.url, region, lang),
    subheading: data?.subheading && toHTML(data.subheading[lang]),
    ...(data.image
      ? { image: toImageProps(data.image, lang) }
      : { bodyText: toHTML(data.bodyText[lang]) }),
  };
}
