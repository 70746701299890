import { FlavorProfileProps } from "@bluebottlecoffee/design-system/components";
import { FlavorProfile as Schema } from "../sanity-schema";

export type FlavorProfileCopyTranslations = {
  heading?: string;
  onAScaleFrom?: string;
  to?: string;
};

export function toFlavorProfileProps(
  data: Schema,
  lang: string,
  copy?: FlavorProfileCopyTranslations,
  bgColor?: FlavorProfileProps["bgColor"],
): FlavorProfileProps {
  const { heading, to, onAScaleFrom } = copy;
  return {
    ...(bgColor && { bgColor }),
    ...(heading && { heading }),
    ...(onAScaleFrom &&
      onAScaleFrom &&
      to && {
        sRText: {
          onAScaleFrom,
          to,
        },
      }),
    tastingNotes: data.tastingNotes[lang],
    tasteScales: data.tasteScales.map((s) => ({
      scaleTitle: s.scaleTitle[lang],
      minValueLabel: s.minValueLabel[lang],
      maxValueLabel: s.maxValueLabel[lang],
      tasteValue: s.tasteValue,
    })),
  };
}
