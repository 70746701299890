import { AppDownloadProps } from "@bluebottlecoffee/design-system/components/modules";
import { Color } from "@bluebottlecoffee/design-system/components/lib/types";
import { AppDownload as AppDownloadSchema } from "../sanity-schema";
import { toImageProps } from "./image";

type AppDownloadTransformerProps = {
  data: AppDownloadSchema;
  lang: string;
};

export function toAppDownloadProps({
  data,
  lang,
}: AppDownloadTransformerProps): AppDownloadProps {
  const { bgColor, heading, image } = data;
  return {
    ...(bgColor && { bgColor: bgColor as Color }),
    heading: heading[lang],
    image: toImageProps(image, lang),
  };
}
