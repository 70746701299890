import Honeybadger from "@honeybadger-io/js";
import { Result } from "@bluebottlecoffee/design-system/components/lib/types";
import {
  IterableSubscribeToListResponse,
  SubscribeToIterableListBody,
  SubscribeToListErrorResponse,
  SubscribeToListResponse,
} from "../../../shared-types";
import { fetcher } from "../fetcher";

type SubscribeToListProps = SubscribeToIterableListBody & {
  genericError: string;
  invalidEmailError: string;
};

/**
 * A guard statement to tell whether the returned value is a success
 *
 * Provides type narrowing for the {@link SubscribeToListResponse} type.
 * If the function results in `true`, the returned value is
 * a {@link IterableSubscribeToListResponse}.
 *
 */
function hasIterableResponse(
  value: SubscribeToListResponse,
): value is IterableSubscribeToListResponse {
  return (
    Object.hasOwn(value, "successCount") &&
    Object.hasOwn(value, "failCount") &&
    Object.hasOwn(value, "invalidEmails")
  );
}

/**
 * Checks to see if Iterable is reporting a failure.
 * */
function hasIterableFailure({ failCount }: IterableSubscribeToListResponse) {
  return failCount > 0;
}

/**
 * A guard statement to tell whether the returned value is an error
 *
 * Provides type narrowing for the {@link SubscribeToListResponse} type.
 * If the function results in `true`, the returned value is
 * a {@link SubscribeToListErrorResponse}.
 */
function hasApiError(
  value: SubscribeToListResponse,
): value is SubscribeToListErrorResponse {
  return Object.hasOwn(value, "message");
}

/**
 * Sends a post request to the Cloudflare function responsible for reaching
 * out to Iterable and subscribing the provided email.
 *
 * Unexpected exceptions are passed to Honeybadger for tracking.
 *
 * @returns a {@link Result} interpreted from the API response.
 */
export const subscribeToList = async ({
  email,
  genericError,
  invalidEmailError,
  listId,
}: SubscribeToListProps): Promise<Result> => {
  // env var only needs to be populated in development/local environments
  const base = process.env.NEXT_PUBLIC_CLOUDFLARE_WORKER_API_HOST || "";
  const endpoint = `${base}/api/v1/iterable/subscribe-to-list`;

  /**
   * Creates a {@link Result} by interpreting the response from our
   * Cloudflare API.
   * */
  function handleResponse(data: SubscribeToListResponse): Promise<Result> {
    // Guard against errors from our API
    if (hasApiError(data)) {
      return Promise.resolve({
        success: false,
        message: data.message,
      });
    }

    // Interpret the response from Iterable and provide the appripriate
    // error message
    const maybeIterableErrorMessage = (): string => {
      // The first function here performs type narrowing via a TS guard statement
      // before checking to see whether or not there's a failure.
      if (hasIterableResponse(data) && hasIterableFailure(data)) {
        if (data.invalidEmails?.length > 0) return invalidEmailError;

        return genericError;
      }

      return ""; // Successful, no errors
    };

    return Promise.resolve({
      success: !!data.successCount,
      message: maybeIterableErrorMessage(),
    });
  }

  const result = await fetcher(endpoint, {
    method: "POST",
    body: JSON.stringify({ listId, email }),
  })
    .then(handleResponse)
    .catch((err) => {
      Honeybadger.notify(err, "Failed to subscribe email to list");
      return { success: false, message: err.message };
    });

  return result;
};
