import {
  Carousel,
  CollectionsWithNav,
  FlavorAndRoastCard,
  FlavorAndRoastCardProps,
  FlavorProfileProps,
  useScreenSizeContext,
} from "@bluebottlecoffee/design-system/components";
import { FunctionComponent, useMemo, useState } from "react";
import { FlavorAndRoastCarouselProjection } from "../lib/sanity/quick-shop-carousel-queries";
import { toImageProps } from "../lib/transformers/image";
import {
  FlavorProfileCopyTranslations,
  toFlavorProfileProps,
} from "../lib/transformers/flavor-profile";
import { ConversionSchema } from "../lib/transformers/conversion";
import { productPage } from "../lib/link-builders";

// Start Product comparison on homepage ABC test
interface FlavorAndRoastCollectionProp
  extends FlavorAndRoastCarouselProjection {
  lang: string;
  region: string;
  flavorProfileCopy: FlavorProfileCopyTranslations;
}

// Todo: if the experiment wins, move this to a transformers folder
const getFlavorAndRoastModule = (
  product: ConversionSchema,
  lang: string,
  flavorProfileCopy: FlavorProfileCopyTranslations,
): FlavorProfileProps | null => {
  let flavorAndRoastModule = null;

  product.modules.forEach((module) => {
    if (module._type === "twoUp") {
      module.components.forEach((component) => {
        if (component._type === "flavorProfile") {
          flavorAndRoastModule = toFlavorProfileProps(
            component,
            lang,
            flavorProfileCopy,
          );
        }
      });
    }
  });

  return flavorAndRoastModule;
};

type ReturnTypeFlavorAndRoastCards =
  FlavorAndRoastCollectionProp["filters"][0] & {
    products: FlavorAndRoastCardProps[];
  };

// Todo: if the experiment wins, move this to a transformers folder
const toFlavorAndRoastCards = ({
  filters,
  lang,
  cta,
  region,
  flavorProfileCopy,
}: {
  filters: FlavorAndRoastCollectionProp["filters"];
  cta: FlavorAndRoastCollectionProp["cta"];
  lang: string;
  region: string;
  flavorProfileCopy: FlavorProfileCopyTranslations;
}): ReturnTypeFlavorAndRoastCards[] =>
  filters.reduce((obj, filter) => {
    const productsWithData: FlavorAndRoastCardProps[] = filter.products.map(
      (product) => {
        const flavorAndRoastData = getFlavorAndRoastModule(
          product,
          lang,
          flavorProfileCopy,
        );

        return {
          name: product.name[lang],
          flavor: {
            scaleTitle: flavorAndRoastData.tasteScales[0].scaleTitle,
            tastingNotes: flavorAndRoastData.tastingNotes,
          },
          image: toImageProps(product.images[0].desktop, lang),
          link: {
            text: cta,
            url: productPage({ lang, region, slug: product.slug.current }),
          },
          method: product.primaryShopCollectionName,
          roast: flavorAndRoastData.tasteScales[1],
        } as FlavorAndRoastCardProps;
      },
    );

    return {
      ...obj,
      [filter.id]: productsWithData,
    };
  }, []);

export const FlavorAndRoastCollection: FunctionComponent<
  FlavorAndRoastCollectionProp
> = ({ heading, cta, filters, lang, region, flavorProfileCopy }) => {
  const [activeFilter, setActiveFilter] = useState(filters[0].id);
  const { isMobile } = useScreenSizeContext();
  const filtersNormalize = useMemo(
    () =>
      toFlavorAndRoastCards({
        filters,
        cta,
        lang,
        region,
        flavorProfileCopy,
      }),
    [],
  );

  return (
    <CollectionsWithNav
      title={heading}
      navigation={filters}
      activeNav={activeFilter}
      setActiveNav={setActiveFilter}
      bgColor="beige"
      titleTextSize="3xl"
      titleColor="grey"
      CollectionNode={
        <Carousel
          nElementsToDisplay={isMobile() ? 1 : 3}
          title={heading}
          showTitle={false}
          showIndexes
          className="flavorAndRoastCollection-carousel"
          listClass="flavorAndRoastCollection-listClass"
          listItemClass="flavorAndRoastCollection-listItemClass"
          insetButtons
        >
          {filtersNormalize[activeFilter].map(
            (props: FlavorAndRoastCardProps) => (
              <FlavorAndRoastCard
                {...props}
                key={`FlavorAndRoastCard-${props.name}`}
              />
            ),
          )}
        </Carousel>
      }
    />
  );
};
