import { toHTML } from "@portabletext/to-html";
import {
  CopyColumnProps,
  InlineKeyValueListWithIconProps,
  ExpandedKeyValueListProps,
} from "@bluebottlecoffee/design-system/components";
import type { BulletedKeyValue } from "@bluebottlecoffee/design-system/components/lib/types";
import {
  CopyColumn as CopyColumnSchema,
  InlineKeyValueListWithIcon,
  ExpandedKeyValueList,
  CopyColumnFreeFlow,
} from "../sanity-schema";
import { toLinkProps } from "./link";
import { toImageProps } from "./image";

function toInlineKeyValueListWithIconProps(
  data: InlineKeyValueListWithIcon,
  locale: string,
): InlineKeyValueListWithIconProps {
  return {
    icon: toImageProps(data.icon, locale),
    keyValues: data.keyValueList.map(
      (kv) =>
        ({
          key: kv.key[locale],
          value: toHTML(kv.value[locale]),
        }) as BulletedKeyValue,
    ),
  } as InlineKeyValueListWithIconProps;
}

function toExpandedKeyValueListProps(
  data: ExpandedKeyValueList,
  locale: string,
): ExpandedKeyValueListProps {
  return {
    hasTopBorders: data.hasTopBorders,
    keyValues: data.keyValueList.map(
      (kv) =>
        ({
          key: kv.key[locale],
          value: toHTML(kv.value[locale]),
        }) as BulletedKeyValue,
    ),
  } as ExpandedKeyValueListProps;
}

export function toCopyColumnProps(
  data: CopyColumnSchema | CopyColumnFreeFlow,
  region: string,
  lang: string,
): CopyColumnProps {
  // @Sebas Remove this When Flavor and Roast experiment ends
  // @ts-ignore
  if (data?.skipTransform) {
    // @ts-ignore
    return data;
  }

  return {
    ...(data.cta?.text && { cta: toLinkProps(data.cta, region, lang) }),
    ...(data._type === "copyColumnFreeFlow" &&
      data.hasYMargins && {
        hasYMargins: data.hasYMargins,
      }),
    copyBlockProps: {
      ...(data.cta && { smallText: true }),
      heading: data.copyBlock?.heading && data.copyBlock.heading[lang],
      subheading:
        data.copyBlock?.subheading && toHTML(data.copyBlock.subheading[lang]),
      description:
        data.copyBlock?.description && toHTML(data.copyBlock.description[lang]),
    },
    divider: data.divider,
    keyValueListProps: data.keyValueList && {
      _type: data.keyValueList[0]._type,
      ...(data.keyValueList[0]._type === "inlineKeyValueListWithIcon"
        ? toInlineKeyValueListWithIconProps(data.keyValueList[0], lang)
        : toExpandedKeyValueListProps(data.keyValueList[0], lang)),
    },
  };
}
