import { toHTML } from "@portabletext/to-html";
import { HeroProps } from "@bluebottlecoffee/design-system/components";
import {
  Hero as HeroSchema,
  HeroResponsive as HeroResponsiveSchema,
  ImageResponsive,
  ImageWithAltText,
} from "../sanity-schema";
import { toLinkProps } from "./link";
import { toImageResponsiveProps } from "./image";
import { toVideoProps } from "./video";

function validateData(data: HeroSchema | HeroResponsiveSchema, lang: string) {
  if (!data) return;

  let missingSource: boolean | undefined = false;

  if (data._type === "hero") {
    missingSource = data.image && data.image.altText && !data.image.source;
  } else {
    if (data.image.mobile) {
      missingSource =
        data.image && data.image.mobile.altText && !data.image.mobile.source;
    }
    missingSource =
      missingSource &&
      data.image &&
      data.image.desktop.altText &&
      !data.image.desktop.source;
  }

  const headline = data?.headline?.[lang];
  if (missingSource) {
    throw new Error(
      `The Image on${
        typeof headline === "string" ? ` ${headline}` : ""
      } Hero needs a source`,
    );
  }
}

function isResourceVideo(image: ImageWithAltText | ImageResponsive): boolean {
  if (image._type === "imageWithAltText") {
    return image.source.resource_type === "video";
  } else {
    return image.desktop.source.resource_type === "video";
  }
}

export function toHeroProps(
  data: HeroSchema | HeroResponsiveSchema,
  region: string,
  lang: string,
): HeroProps {
  validateData(data, lang);

  const parsedHeadline = data.headline ? toHTML(data.headline[lang]) : "";

  return {
    ...(data.ctas && {
      links: data.ctas.map((cta) => {
        try {
          return toLinkProps(cta, region, lang);
        } catch (e) {
          throw Error(`${parsedHeadline} Hero CTA link error: ${e.message}`);
        }
      }),
    }),
    ...(data.headline && { text: parsedHeadline }),
    ...(data.subtext && { subtext: toHTML(data.subtext[lang]) }),
    ...(data.image &&
      (isResourceVideo(data.image)
        ? { video: toVideoProps(data.image, lang) }
        : { image: toImageResponsiveProps(data.image, lang) })),
    centered: data.alignment === "center",
    ...(data.narrowHeight && { narrowHeight: data.narrowHeight }),
    darkTheme: data.colorTheme === "dark",
  };
}
