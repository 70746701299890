import { CategoryGridProps } from "@bluebottlecoffee/design-system/components";
import { CategoryGrid } from "../sanity-schema";
import { toImageProps } from "./image";
import { toInternalUrl } from "./link";

interface ToCategoryGridProps {
  data: CategoryGrid;
  lang: string;
  region: string;
}

export const toCategoryGrid = ({
  data,
  lang,
  region,
}: ToCategoryGridProps): CategoryGridProps => ({
  heading: data.heading[lang],
  cards: data.cards.map((card) => ({
    heading: card.heading[lang],
    image: toImageProps(card.image, lang),
    subHeading: card.subHeading[lang],
    url: toInternalUrl({ link: card.internalUrl, lang, region }),
  })),
});
